<template>
  <div class="page-works">
    <div class="bg-vid" ref="bgVid">
      <div class="h-100 w-100" v-for="{ id } in $t('categories.data')" :key="id">
        <transition name="fade">
          <video autoplay muted loop playsinline v-if="parent_id === id">
            <source :src="require(`@/assets/video/${parent_id}.mp4`)" />Your browser does not support the video tag.
          </video>
        </transition>
      </div>
    </div>

    <div class="content h-100 d-flex align-items-center">
      <b-row class="w-100">
        <b-col cols="6" class="h-100 d-flex align-items-center">
          <div>
            <div class="cat" :class="activeCat === id ? 'activeCat' : ''"
              v-for="{ name, id, slug } in $t('categories.data')" :key="id" @click="parent_id = id, enter(id)">
              <!-- <router-link :to="`/works/${slug}`"> -->
              <h2 v-if="id !== 14">{{ name }}</h2>

              <router-link v-if="id === 14" :to="`/works/${slug}`">
                <h2>{{ name }}</h2>
              </router-link>

              <!-- </router-link> -->
            </div>
          </div>
        </b-col>
        <b-col cols="6" class="h-100 d-flex align-items-center" v-if="subCats">
          <div>
            <div v-for="subCat in subCats" ref="subCatItems" class="subCatItem" :key="subCat.id">
              <router-link :to="`/works/${subCat.parent_slug}/${subCat.slug}`">
                <h2>{{ subCat.name }}</h2>
              </router-link>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// import { bus } from '@/main'
// import { mapGetters } from 'vuex'
export default {
  name: 'Works',
  metaInfo: {
    title: 'Our Work'
  },
  data() {
    return {
      parent_id: 1,
      activeCat: null
    }
  },
  computed: {
    // ...mapGetters(['allWorks'])
    subCats() {
      // console.log(this.$t('categories.subCats').filter(el => (el.parent_id === this.parent_id)))
      return this.$t('categories.subCats').filter(el => (el.parent_id === this.parent_id))
    },
    videoUrl() {
      return null
    },
    allCats() {
      return this.$t('categories')
    }

  },
  methods: {
    enter(val) {
      const targets = this.$refs.subCatItems
      this.activeCat = this.parent_id
      setTimeout(() => {
        this
          .$anime({
            targets,
            translateY: [250, 0],
            opacity: [0, 1],
            delay: this.$anime.stagger(100),
            easing: 'easeOutExpo',
            duration: 1000
          })
      }, 50)
    }
  },

  mounted() {
    // const b = 66 + (13.7 * 78) + (5 * 180) - (6.8 * 34)
    // console.log(b * 1.2)
  }
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.page-works {
  // margin-top: 150px;
  // position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  min-height: 700px;
  position: relative;

  a {
    text-decoration: none;
  }

  .cat {
    h2 {
      cursor: pointer;

      font-size: 1.2rem;

      &:hover {
        color: $bronze;
        font-weight: 400 !important;
      }
    }
  }

  .cat:first-child {
    h2 {
      padding-top: 0 !important;
    }
  }

  .activeCat {
    h2 {
      font-size: 1.5rem;
      margin: 0;
      transition: padding 0.4s ease-in-out;
      padding: 20px 0;
      color: $bronze;
      font-weight: 400 !important;
    }
  }

  .subCatItem {
    opacity: 0;

    a:hover {
      text-decoration: none;
    }

    h2 {
      font-size: 1.2rem;

      &:hover {
        color: $bronze;
        font-weight: 400 !important;
      }
    }
  }

  .bg-vid {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    filter: brightness(0.5);

    video {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  @media (min-width: 768px) {
    .cat {
      h2 {
        font-size: 2rem;
      }
    }

    .subCatItem {
      h2 {
        font-size: 2rem;
      }
    }
  }
}
</style>
